@keyframes title-pad {
  0% {
    padding-left: 1px;
  }
  50% {
    padding-left: 5px;
  }
  100% {
    padding-left: 5px;
  }
}

.card-style {
  border-color: #f2e5d9 !important;
  border-radius: 15px 30px !important;
}

.card-header {
  border-color: #f2e5d9 !important;
  background-color: transparent !important;
}

.card-footer {
  border-color: #f2e5d9 !important;
  background-color: transparent !important;
}

.container-border {
  border: 1px solid #f2e5d9 !important;
  border-radius: 15px 30px !important;
}

.item-link {
  color: black !important;
  text-decoration: none !important;
}

.item-link:hover {
  color: #a18167 !important;
  animation-name: title-pad;
  animation-duration: 5s;
}

.image {
  min-height: 20vh !important;
  max-height: 100vh !important;
  border: 1px solid #f2e5d9 !important;
  border-radius: 15px 0px 0px 30px !important;
}

.image:hover {
  filter: grayscale(30%) !important;
}

.selected-image {
  min-height: 20vh !important;
  max-height: 100vh !important;
  border: 1px solid #f2e5d9 !important;
  border-radius: 15px 30px !important;
}

.button-title {
  padding: none !important;
  color: #a18167 !important;
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}
