.success {
  background-color: #a18167 !important;
  border-color: #b4a090 !important;
}

.success:hover,
.success:active {
  background-color: #a18167d5 !important;
  border-color: rgba(161, 129, 103, 0.418) !important;
  box-shadow: none !important;
}

.disabled {
  color: black !important;
  background-color: #f2e5d9 !important;
  border-color: #f2e5d9 !important;
  box-shadow: none !important;
}

.text-bar:hover,
.text-bar:focus,
.text-bar:active {
  border-color: #a18167 !important;
  box-shadow: none !important;
}

.admin li {
  border-color: #a18167 !important;
  border-bottom-color: transparent !important;
}
.admin a:hover {
  border-color: #a18167 !important;
  border-bottom-color: transparent !important;
}
.admin a:focus,
.admin a:active {
  color: #a18167 !important;
  border-color: #a18167 !important;
  border-bottom-color: transparent !important;
}

.admin:focus,
.admin {
  border-color: #a18167 !important;
  box-shadow: none !important;
}
.admin a {
  color: #a18167 !important;
  border-bottom-color: transparent !important;
}

.card-style {
  border-color: #f2e5d9 !important;
  border-radius: 15px 30px !important;
}

.card-inside {
  border-color: #f2e5d9 !important;
  background-color: transparent !important;
}
