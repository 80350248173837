@import url("https://fonts.googleapis.com/css2?family=Anton&family=Montserrat:wght@300&display=swap");
* {
  font-family: "Montserrat", sans-serif;
}

.background {
  background-color: #ffffff !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Anton";
}
