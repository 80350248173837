@import url(https://fonts.googleapis.com/css2?family=Anton&family=Montserrat:wght@300&display=swap);
@-webkit-keyframes pad {
  0% {
    color: darkgoldenrod !important;
    border-bottom: 1px solid #a18167;
  }
  50% {
    color: darkgoldenrod !important;
    margin-right: 4px;
  }
  100% {
    color: darkgoldenrod !important;
    border-bottom: 1px solid #a18167;
    margin-right: 4px;
  }
}

@keyframes pad {
  0% {
    color: darkgoldenrod !important;
    border-bottom: 1px solid #a18167;
  }
  50% {
    color: darkgoldenrod !important;
    margin-right: 4px;
  }
  100% {
    color: darkgoldenrod !important;
    border-bottom: 1px solid #a18167;
    margin-right: 4px;
  }
}

.search-bar {
  border-color: #a18167 !important;
}

.search-bar:hover,
.search-bar:focus,
.search-bar:active {
  border-color: #f2e5d9 !important;
  box-shadow: none !important;
}

.search-button {
  color: white !important;
  background-color: #a18167 !important;
  border-color: #a18167 !important;
}

.search-button:hover,
.search-button:focus,
.search-button:active {
  border-color: #f2e5d9 !important;
  box-shadow: none !important;
}

.background-nav {
  background-color: #f2e5d9 !important;
}

.link {
  color: darkgoldenrod !important;
  padding-bottom: 2px !important;
}

.line {
  height: 1px;
  background: -webkit-linear-gradient(
    left,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(0, 0, 0, 0.3) 10%,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0.3) 90%,
    rgba(0, 0, 0, 0.7) 100%
  );
}

.search-values {
  background-color: white !important;
}

.search-values:hover,
.search-values:focus,
.search-values:active {
  color: white !important;
  border-color: #a18167 !important;
  background-color: #a18167 !important;
  box-shadow: none !important;
}

@media (min-width: 992px) {
  .link:hover {
    -webkit-animation-name: pad !important;
            animation-name: pad !important;
    -webkit-animation-duration: 5s;
            animation-duration: 5s;
  }
  .hidden-lg {
    display: none !important;
  }
  .hidden-md {
    top: 7vh !important;
    right: 2vw !important;
  }
}

@media (max-width: 991px) {
  .hidden-md {
    display: none !important;
  }
}

.bg-footer {
  background-color: #f2e5d9 !important;
}

/* Logo color - #a18167 */
.link-footer {
  color: darkgoldenrod !important;
}

.link-footer:hover {
  text-decoration: none;
}

@-webkit-keyframes title-pad {
  0% {
    padding-left: 1px;
  }
  50% {
    padding-left: 5px;
  }
  100% {
    padding-left: 5px;
  }
}

@keyframes title-pad {
  0% {
    padding-left: 1px;
  }
  50% {
    padding-left: 5px;
  }
  100% {
    padding-left: 5px;
  }
}

.card-style {
  border-color: #f2e5d9 !important;
  border-radius: 15px 30px !important;
}

.card-header {
  border-color: #f2e5d9 !important;
  background-color: transparent !important;
}

.card-footer {
  border-color: #f2e5d9 !important;
  background-color: transparent !important;
}

.container-border {
  border: 1px solid #f2e5d9 !important;
  border-radius: 15px 30px !important;
}

.item-link {
  color: black !important;
  text-decoration: none !important;
}

.item-link:hover {
  color: #a18167 !important;
  -webkit-animation-name: title-pad;
          animation-name: title-pad;
  -webkit-animation-duration: 5s;
          animation-duration: 5s;
}

.image {
  min-height: 20vh !important;
  max-height: 100vh !important;
  border: 1px solid #f2e5d9 !important;
  border-radius: 15px 0px 0px 30px !important;
}

.image:hover {
  -webkit-filter: grayscale(30%) !important;
          filter: grayscale(30%) !important;
}

.selected-image {
  min-height: 20vh !important;
  max-height: 100vh !important;
  border: 1px solid #f2e5d9 !important;
  border-radius: 15px 30px !important;
}

.button-title {
  padding: none !important;
  color: #a18167 !important;
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

.success {
  background-color: #a18167 !important;
  border-color: #b4a090 !important;
}

.success:hover,
.success:active {
  background-color: #a18167d5 !important;
  border-color: rgba(161, 129, 103, 0.418) !important;
  box-shadow: none !important;
}

.disabled {
  color: black !important;
  background-color: #f2e5d9 !important;
  border-color: #f2e5d9 !important;
  box-shadow: none !important;
}

.text-bar:hover,
.text-bar:focus,
.text-bar:active {
  border-color: #a18167 !important;
  box-shadow: none !important;
}

.admin li {
  border-color: #a18167 !important;
  border-bottom-color: transparent !important;
}
.admin a:hover {
  border-color: #a18167 !important;
  border-bottom-color: transparent !important;
}
.admin a:focus,
.admin a:active {
  color: #a18167 !important;
  border-color: #a18167 !important;
  border-bottom-color: transparent !important;
}

.admin:focus,
.admin {
  border-color: #a18167 !important;
  box-shadow: none !important;
}
.admin a {
  color: #a18167 !important;
  border-bottom-color: transparent !important;
}

.card-style {
  border-color: #f2e5d9 !important;
  border-radius: 15px 30px !important;
}

.card-inside {
  border-color: #f2e5d9 !important;
  background-color: transparent !important;
}

.col-right {
  border-left: 1px solid #a18167 !important;
}

.row-bottom {
  border-top: 1px solid #a18167 !important;
}

.product-item {
  border-color: #a18167 !important;
}

.product-item:hover {
  color: white !important;
  background-color: #a18167 !important;
}

.product-selected-card {
  border-radius: 15px !important;
  border-color: #a18167 !important;
}

.product-selected-inner {
  border-color: #a18167 !important;
}

.success {
  background-color: #a18167 !important;
  border-color: #b4a090 !important;
}

.success:hover,
.success:active {
  background-color: #a18167d5 !important;
  border-color: rgba(161, 129, 103, 0.418) !important;
  box-shadow: none !important;
}

.disabled {
  color: black !important;
  background-color: #f2e5d9 !important;
  border-color: #f2e5d9 !important;
  box-shadow: none !important;
}

* {
  font-family: "Montserrat", sans-serif;
}

.background {
  background-color: #ffffff !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Anton";
}

