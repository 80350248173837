@keyframes pad {
  0% {
    color: darkgoldenrod !important;
    border-bottom: 1px solid #a18167;
  }
  50% {
    color: darkgoldenrod !important;
    margin-right: 4px;
  }
  100% {
    color: darkgoldenrod !important;
    border-bottom: 1px solid #a18167;
    margin-right: 4px;
  }
}

.search-bar {
  border-color: #a18167 !important;
}

.search-bar:hover,
.search-bar:focus,
.search-bar:active {
  border-color: #f2e5d9 !important;
  box-shadow: none !important;
}

.search-button {
  color: white !important;
  background-color: #a18167 !important;
  border-color: #a18167 !important;
}

.search-button:hover,
.search-button:focus,
.search-button:active {
  border-color: #f2e5d9 !important;
  box-shadow: none !important;
}

.background-nav {
  background-color: #f2e5d9 !important;
}

.link {
  color: darkgoldenrod !important;
  padding-bottom: 2px !important;
}

.line {
  height: 1px;
  background: -webkit-linear-gradient(
    left,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(0, 0, 0, 0.3) 10%,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0.3) 90%,
    rgba(0, 0, 0, 0.7) 100%
  );
}

.search-values {
  background-color: white !important;
}

.search-values:hover,
.search-values:focus,
.search-values:active {
  color: white !important;
  border-color: #a18167 !important;
  background-color: #a18167 !important;
  box-shadow: none !important;
}

@media (min-width: 992px) {
  .link:hover {
    animation-name: pad !important;
    animation-duration: 5s;
  }
  .hidden-lg {
    display: none !important;
  }
  .hidden-md {
    top: 7vh !important;
    right: 2vw !important;
  }
}

@media (max-width: 991px) {
  .hidden-md {
    display: none !important;
  }
}
