.col-right {
  border-left: 1px solid #a18167 !important;
}

.row-bottom {
  border-top: 1px solid #a18167 !important;
}

.product-item {
  border-color: #a18167 !important;
}

.product-item:hover {
  color: white !important;
  background-color: #a18167 !important;
}

.product-selected-card {
  border-radius: 15px !important;
  border-color: #a18167 !important;
}

.product-selected-inner {
  border-color: #a18167 !important;
}

.success {
  background-color: #a18167 !important;
  border-color: #b4a090 !important;
}

.success:hover,
.success:active {
  background-color: #a18167d5 !important;
  border-color: rgba(161, 129, 103, 0.418) !important;
  box-shadow: none !important;
}

.disabled {
  color: black !important;
  background-color: #f2e5d9 !important;
  border-color: #f2e5d9 !important;
  box-shadow: none !important;
}
