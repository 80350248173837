.bg-footer {
  background-color: #f2e5d9 !important;
}

/* Logo color - #a18167 */
.link-footer {
  color: darkgoldenrod !important;
}

.link-footer:hover {
  text-decoration: none;
}
